import Application from "@ember/application";
import runtimeEnvironment from "core/utils/runtime-environment";
import loadInitializers from "ember-load-initializers";
import Resolver from "ember-resolver";
import ENV from "parog-web/config/environment";
import ControlTower from "wise-control-tower";
import "./deprecation-workflow";

if (ENV["@sentry/ember"].sentry.dsn) {
  ControlTower.initialize({
    environment: runtimeEnvironment(),
    sentryOptions: ENV["@sentry/ember"].sentry
  });
}

export default class App extends Application {
  modulePrefix = ENV.modulePrefix;
  podModulePrefix = ENV.podModulePrefix;
  Resolver = Resolver;

  engines = {};
}

loadInitializers(App, ENV.modulePrefix);
