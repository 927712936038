import Patient from "core/models/patient";
import { PatientApiFilters } from "core/utils/patient-list-filter-serializer";

export default function filterPatients(
  patients: Patient[],
  patientApiFilters: PatientApiFilters
): Patient[] {
  const filters: ((patient: Patient, filters: PatientApiFilters) => boolean)[] =
    [
      isEnrolled,
      isFollowedByHealthCentres,
      isNotEnrolled,
      isDisenrolled,
      isFollowedByMedics
    ];
  return patients.filter(patient =>
    filters.every(filter => filter(patient, patientApiFilters))
  );
}

function isEnrolled(patient: Patient, filters: PatientApiFilters): boolean {
  return filters.enrolled === undefined || patient.isEnrolled;
}

function isNotEnrolled(patient: Patient, filters: PatientApiFilters): boolean {
  return filters.not_enrolled === undefined || patient.isNotYetEnrolled;
}

function isDisenrolled(patient: Patient, filters: PatientApiFilters): boolean {
  return filters.disenrolled === undefined || patient.isDisenrolled;
}

function isFollowedByMedics(patient, filters: PatientApiFilters): boolean {
  return (
    filters.medics === undefined ||
    patient.medics.some(medic => filters.medics!.includes(medic.id))
  );
}

function isFollowedByHealthCentres(
  patient: Patient,
  filters: PatientApiFilters
): boolean {
  return (
    filters.health_centres === undefined ||
    filters.health_centres.includes(patient.healthCentre.id)
  );
}
