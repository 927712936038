/* eslint-disable ember/no-runloop */
import { cancel, later, run } from "@ember/runloop";
import {
  getItemFromLocalStorageWithoutPrefix,
  setItemInLocalStorageWithoutPrefix
} from "core/utils/use-local-storage";
import Ember from "ember";
import RSVP from "rsvp";
import ControlTower from "wise-control-tower";

export function scheduleAccessTokenRefresh(this, data) {
  if (!Ember.testing) {
    const expiresAt = tokenExpiresAt(data.access_token);
    const refreshToken = data.refresh_token;
    const now = new Date().getTime();
    const offset = tokenRefreshOffset();

    if (expiresAt > now - offset) {
      cancel(this._refreshTokenTimeout);
      delete this._refreshTokenTimeout;
      this._refreshTokenTimeout = later(
        this,
        refreshAccessToken,
        refreshToken,
        expiresAt - now - offset
      );
    }
  }
}

export function isTokenValid(token) {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expirationTimestamp = payload.exp * 1000;
    const todayTimestamp = new Date().getTime();

    return todayTimestamp < expirationTimestamp;
  } catch (e) {
    ControlTower.captureException(e);
    return false;
  }
}

export function refreshAccessToken(this, refreshToken) {
  return new RSVP.Promise((resolve, reject) => {
    callRefreshTokenEndpoint.call(this, refreshToken).then(
      ({ response }) => {
        run(() => {
          const data = {
            access_token: response.jwt,
            refresh_token: refreshToken
          };

          updateSessionAccessToken.call(this, data.access_token);
          scheduleAccessTokenRefresh.call(this, data);
          resolve(data);
        });
      },
      response => {
        reject();
        throw `Access token could not be refreshed - server responded with ${response.responseJSON}.`;
      }
    );
  });
}

export async function callRefreshTokenEndpoint(this, refreshToken) {
  const data = {
    refresh_token: refreshToken
  } as unknown as BodyInit;

  const { content } = await this.requestManager.request({
    url: "/api/v1/refresh_token",
    method: "POST",
    body: JSON.stringify(data)
  });

  return content;
}

function tokenExpiresAt(token) {
  const payload = JSON.parse(atob(token.split(".")[1]));
  return payload.exp * 1000;
}

function tokenRefreshOffset(): number {
  const min = 5;
  const max = 10;

  return (Math.floor(Math.random() * (max - min)) + min) * 1000;
}

export function updateSessionAccessToken(this, accessToken) {
  // Set access token value in session
  this.session.access_token = accessToken;
  this.session.data.authenticated.access_token = accessToken;

  // Set access token value in storafe
  const session = getItemFromLocalStorageWithoutPrefix(
    "ember_simple_auth-session"
  ) as string;
  const authenticated = JSON.parse(session).authenticated;

  const newObject = {
    authenticated: Object.assign(authenticated, { access_token: accessToken })
  };

  setItemInLocalStorageWithoutPrefix(
    "ember_simple_auth-session",
    JSON.stringify(newObject)
  );
}
