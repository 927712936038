/* eslint-disable ember/no-runloop */
import { getOwner } from "@ember/-internals/owner";
import { cancel, Timer } from "@ember/runloop";
import { service } from "@ember/service";
import NativeAppService from "core/services/native-app";
import SemeiaSessionService from "core/services/session";
import AuthUtils from "core/utils/authentication";
import Base from "ember-simple-auth/authenticators/base";
import ApplicationAdapter from "parog-web/adapters/application";
import ControlTower from "wise-control-tower";
import {
  callRefreshTokenEndpoint,
  isTokenValid,
  scheduleAccessTokenRefresh,
  updateSessionAccessToken
} from "./utils/refresh-access-tokens";
import { revokeToken } from "./utils/revoke-token";

export default class PatientImplicitAuthenticator extends Base {
  @service declare session: SemeiaSessionService;
  @service declare nativeApp: NativeAppService;
  @service requestManager;

  tokenEndpoint = "/api/v1/get_tokens";
  applicationAdapter = getOwner(this)?.lookup(
    "adapter:application"
  ) as ApplicationAdapter;
  declare _refreshTokenTimeout?: Timer;

  async restore(data) {
    if (data.refresh_token) {
      // Do not authenticate if refresh token is expired
      if (!isTokenValid(data.refresh_token)) {
        ControlTower.resetUser();
        throw "Patient Implicit Authenticator - Invalid refresh token";
      }

      // If access token is expired, refresh it
      if (!isTokenValid(data.access_token)) {
        const { response } = await callRefreshTokenEndpoint.call(
          this,
          data.refresh_token
        );
        data.access_token = response.jwt;

        updateSessionAccessToken.call(this, data.access_token);
      }

      // Otherwise, do nothing and schedule next access token refresh
      scheduleAccessTokenRefresh.call(this, data);
    }

    ControlTower.setPatientUser(data);
    await this.session.fetchAuthenticatedUser(data);
    return data;
  }

  async authenticate(requestData) {
    // If the patient is already authenticated, keep him logged in
    // with the previous access token if the new one is already expired
    if (
      this.session.isAuthenticated &&
      this.session.data.authenticated.as === "patient"
    ) {
      this.nativeApp.patientLoggedIn();
      return this.session.data.authenticated;
    }

    // If a refresh token exists in the local storage, it means that the user
    // had already logged in.
    // TODO  : Check of the RT & fetch of an AT will be done somewhere
    const refreshTokenInStorage = Boolean(
      AuthUtils.getRefreshTokenFromStorage()
    );

    if (refreshTokenInStorage) {
      this.nativeApp.patientLoggedIn();
      return this.session.data.authenticated;
    }

    let response;
    try {
      const { content } = await this.requestManager.request({
        url: this.tokenEndpoint,
        method: "POST",
        body: JSON.stringify(requestData)
      });
      response = content;
    } catch ({ response, content }) {
      if (!response.ok) throw content;
    }

    const data = {
      as: "patient",
      id: response.id,
      uuid: response.id,
      health_centre_id: response.health_centre_id,
      access_token: response.access_token,
      refresh_token: response.refresh_token
    };

    ControlTower.setPatientUser(data);
    this.nativeApp.patientLoggedIn();

    this.session.authenticatedAtThisSession = true;
    await this.session.fetchAuthenticatedUser(data);
    return data;
  }

  async invalidate() {
    revokeToken.call(this);
    cancel(this._refreshTokenTimeout);
    delete this._refreshTokenTimeout;
    ControlTower.resetUser();
  }
}
